@use 'global/styles/scss/variables' as vars;


.count-card {
    background-color: vars.$grey-900;
    border-radius: 12px;
    height: 120px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .count-card-left {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .count-card-right {
        > span {
            font-size: 50px;
            color: vars.$grey-800;
        }
    }
}