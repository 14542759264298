$primary-color : #319DFF;
$primary-color-700 : #0077E4;
$grey-900 : #1D232C;
$grey-700 : #586A84;
$grey-800 : #3B4758;
$grey-0 : #161B21;
$dark-bg : #1e1e1e;
$dark-overlay-bg : #262626;
$dark-overlay-shadow : 0 3px 6px -4px rgba(0, 0, 0, .12),
0 6px 16px 0 rgba(0, 0, 0, .08),
0 9px 28px 8px rgba(0, 0, 0, .05);