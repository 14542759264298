@use 'global/styles/scss/variables.scss' as vars;


$border-radius : 12px;
$box-shadow : 0px 4px 20px 0px rgba(7, 6, 18, 0.10);
$page-padding : 0 20px;


.ant-sider {
    .ant-drawer-body {
        padding: 0 !important;
    }
}

.default-layout-wrapper {
    height: 100vh;
    display: flex;
    gap: 20px;
    background-color: vars.$grey-0;
    padding: 10px;



    .default-layout-right {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 20px;
        min-width: 0;

        .default-layout-header {
            background-color: vars.$grey-900;
            border-radius: $border-radius;
            box-shadow: $box-shadow;
            min-height: 80px;
            display: flex;
            align-items: center;
            padding: $page-padding;
            justify-content: space-between;

            .hamburger {
                color: vars.$grey-700;
                transition: 0.3s color;
                cursor: pointer;


                &:hover {
                    color: white;
                }

            }

            .role-tag {
                text-transform: capitalize;
                font-size: 0.8rem;
            }

        }


        .default-layout-page-wrapper {
            flex: 1;
            overflow-y: auto;

        }

    }

}

.default-layout-sider {
    flex-basis: 200px;
    transition: flex-basis 0.5s ease-in-out;
    background-color: vars.$grey-900;
    border-radius: $border-radius ;
    box-shadow: $box-shadow ;
    height: 100%;
    padding: 20px 16px;

    .app-logo {
        margin-bottom: 20px;
        text-align: center;
        padding-bottom: 10px;
        border-bottom: 1px solid #ffffff20;
    }




    .navlinks-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;


        .navlink {
            display: flex;
            padding: 15px 20px;
            transition: 0.3s background-color;
            border-radius: 4px;
            gap: 8px;
            text-decoration: none;
            align-items: center;
            position: relative;
            height: 52px;


            .navlink-text,
            .nav-icon {
                color: vars.$grey-700;
            }

            .navlink-text {
                white-space: nowrap;
                overflow: hidden;
            }


            &.active {
                background-color: vars.$primary-color-700;

                .nav-icon,
                .navlink-text {
                    color: white;
                }

            }

        }
    }




}