.m-0 {
    margin: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.font-bold {
    font-weight: bold !important;
}

.h-100 {
    height: 100% !important;
}

.w-100 {
    width: 100% !important;
}

.uppercase {
    text-transform: uppercase;
}

.p-0 {
    padding: 0 !important;
}

.min-h-100 {
    min-height: 100%;
}