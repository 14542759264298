.coupon-modal-wrapper {
    .hero-image {
        width: 100%;
        max-height: 200px;
    }

    .coupon-modal-heading {
        margin-top: 0;
        margin-bottom: 30px;
    }
}