@use 'global/styles/scss/variables' as vars;


$form-inner-padding :0 20px;


.review-page-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;


    .review-form-wrapper {
        display: flex;
        max-width: 500px;
        width: 100%;
        background-color: vars.$grey-0;
        border-radius: 4px;
        min-height: 480px;
        box-shadow: vars.$dark-overlay-shadow;
        flex-direction: column;

        .review-form-header {
            padding: $form-inner-padding ;
        }

        .review-form-fields {
            padding: $form-inner-padding ;
            padding-top: 15px;
        }

        .review-form-footer {
            display: flex;
            justify-content: space-between;
            padding: $form-inner-padding;
            gap: 5px;
            flex-wrap: wrap;
            padding-bottom: 20px;
        }

    }
}