body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;

}

.ant-form-item-explain-error {
    font-size: 0.7rem !important;
}

.ant-card-head {
    padding-top: 10px !important;

    .ant-card-head-wrapper {
        gap: 10px;

        .ant-card-head-title {
            overflow: visible !important;
        }
    }

    .ant-card-extra {
        margin-inline-start: 0;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
}