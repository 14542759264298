@use 'global/styles/scss/variables' as vars;


$form-inner-padding :0 20px;

.rc-anchor.rc-anchor-normal.rc-anchor-light {
    background-color: red !important;
}


.login-page-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;


    .login-form-wrapper {
        display: flex;
        max-width: 500px;
        width: 100%;
        background-color: vars.$grey-0;
        border-radius: 4px;
        box-shadow: vars.$dark-overlay-shadow;
        flex-direction: column;

        .login-form-header {
            padding: $form-inner-padding ;
        }

        .login-form-fields {
            padding: $form-inner-padding ;
            padding-top: 15px;
        }

        .login-form-footer {
            display: flex;
            justify-content: space-between;
            padding: $form-inner-padding ;
            padding-bottom: 20px;
        }

    }
}

#firebaseui-auth-container {
    flex: 1;
    display: flex;

    &.hidden {
        position: absolute;
        z-index: -1;
    }


    .firebaseui-container {
        margin: auto !important;
        background-color: vars.$grey-0 !important;
        color: vars.$grey-700;
        max-width: 500px !important;


        .firebaseui-card-header {
            border-bottom: 1px solid rgba(253, 253, 253, 0.12);

            .firebaseui-title {
                text-transform: uppercase;
                font-weight: bold
            }
        }


        h1,
        input,
        label,
        span,
        p {
            color: white !important;
        }

        label {
            font-size: 0.8rem;
        }

        .mdl-textfield {
            label {
                &::after {
                    background-color: vars.$primary-color !important;
                }
            }
        }

        .firebaseui-id-submit {
            background: vars.$primary-color !important;
            border-radius: 4px !important;
        }

    }

    .custom-text-btn {
        border: 0;
        background-color: transparent;
        color: white;
        padding: 0.5em 1em;
        transition: 0.3s background-color;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            background-color: #ffffff30;
        }

    }
}